export class DeliveryAddressModel {
  quote_id: any;
  id: any;
  delivery_address_line_1?: any;
  delivery_address_line_2?: any;
  delivery_suburb?: any;
  delivery_town_city?: any;
  delivery_state_region?: any;
  delivery_post_code?: any;
  delivery_country?: any;
  shipping_truck_type_id?: any;
  shipping_rate?: any;
  in_shipping_zone?: any;
  save_without_validate?: any;
  no_of_pallet?: any;
  reset_quote_status?: any;
  is_manual_shipping_rate_change?: any;
  is_warehouse_collection?: any;
  not_revert_quote_status?: any;
  is_select_default_business_address ?:any
  is_sales_rep_delivery ?:any
}
