import {ContactsModel} from "./contacts.model";

export class QuoteOrderSampleModel {
  quote_and_orders: any;
  project: any;
  type: any;
  status: any;
  status_changed: any;
  contact: any;
  account: any;
  address: any;
  guest_user_id: any;
  total_amount: any;
  slip_rating: any;
  id!: any;
  guid: any;
  order_id: any;
  frontend_user_id: any;
  delivery_address_id: any;
  project_name: any;
  in_shipping_zone: any;
  subtotal: any;
  shipping_rate: any;
  tax_gst: any;
  applied_credit: any;
  applied_discount: any;
  final_total: any;
  quote_status!: any;
  notes: any;
  warehouse_arrival_date: any;
  is_archive: any;
  created_at: any;
  modified_at: any;
  status_changed_at: any;
  deliveryAddress: any;
  quoteProducts: any;
  quoteStatusLog: any;
  quotePayments: any;
  frontendUser?: ContactsModel;
  delivery_instructions: any;
  note: any;
  shipping_truck_type_id: any;
  description: any;
  quoteStatus: any;
  activities?: any;
  project_description?: any;
  is_pushed_bigpost?: any;
  quote_number?: any;
  order_number?: any;
  quoteStatusActivity?: any;
  review?: any;
  quoteFeedback?: any;
  site_contact_name?: any;
  site_contact_number?: any;
  site_contact_email?: any;
  shipping_zone?: any;
  is_manual_shipping_rate_change?: any;
  big_post_job_id?: any;
  referenceForBigPost?: any;
  delivery_date?: any;
  leadTime?: any;
  is_delivery_date_mail_sent?: any
  acceptedActivity?: any
  deposit?: any;
  finalAmountDue?: any;
  delivery_address_line_1?: any;
  delivery_address_line_2?: any;
  delivery_suburb?: any;
  delivery_town_city?: any;
  delivery_state_region?: any;
  delivery_post_code?: any;
  delivery_country?: any;
  export_id?: any;
  is_expired?: any;
  //extra for slider design
  available_credit?: any
  eligible_credit_amount?: any
  deposit_amount?: any
  deposit_amount_percentage?: any
  final_due_amount?: any
  final_amount_due?: any
  new_credit?: any
  big_post_delivery_charge?: any
  no_of_pallet?: any
  quote_status_id?: any
  days_to_expiry?: any
  carrier_status_id?: any
  applied_credit_percent?: any
  days_since_order_placement?: any
  stock_required_date?: any
  carrierStatus?: any
  other_truck_type_name?: any
  tracking_number?: any
  logisticType?: any
  packed_by?: any
  total_premium_sealing_price: any = 0;
  total_adhesive_price: any = 0;
  is_select_premium_sealing: boolean = false;
  is_select_adhesive: boolean = false;
  carrier_id?: any
  is_consumable_calculator_applied?: any
  carrier?: any
  is_used_own_transport: any;
  company: any;
  is_draft: any;
  is_deleted: any;
  company_address_id: any;
  frontend_user_address_id: any;
  quoteOverallSealerUnit?: any;
  orderNotAppliedConsumableCalculator?: any;
  disabledAdhesivePrice?: boolean;
  disabledSealerPriceDefender?: boolean;
  disabledSealerPriceGold?: boolean;
  disabledTotalAdhesivePrice?: boolean;
  disabledTotalPremiumSealingPrice?: boolean;
  quoteOrderSentEmail?: any;
  is_disable_expiry_deadline?: any;
  is_stock_required_date_confirm?: any;
  shipping_region_id?: any;
  gpsm_shipment_reference?: any;
  is_disable_gpsm_sync?: any;
  credit_card_surcharge?: any;
  is_credit_card_charge_show?: any;
  stockRequiredDateChangeDetail?: any;
  quoteOrderStatusList?: any;
  total_premium_adhesive?: any;
  total_premium_sealing?: any;
  total?: any;
  quote_order_status_detail?: any;
  is_final_payment_paid?: any;
  is_full_payment_paid?: any;
  is_warehouse_collection?: any;
  total_surfaces_and_accessories?: any;
  full_payment_processing?: any;
  deposit_payment_processing?: any;
  final_payment_processing?: any;
  logistic_type_id?: any;
  is_deposit_slider_adjust: any
  is_follow_up: any
  pendingTasks?: any
  first_warehouse_arrival_date?: any
  quoteOrderWarehouseArrivalDateHistories?: any
  quoteOrderReactivatedActivities?: any
  is_domestic_stock_transfer?: string
  linkedQuoteOrders: any;
  trade_discount: any;
  rrp_amount: any;
  quoteOrderPaymentReceipts: any;
  is_display_all_comments: any;
  backend_user_id: any;
  is_primary_tender: any;
  tender_id: any;
  is_tender: any;
  split_main: number;
  packaging_price: any;
  is_select_default_business_address:any;
  is_sales_rep_delivery :any;
  trade_discount_percentage?:any
}
