import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AlertService, HelperService, SubscribersService} from '@coreservices';
import {Constants} from 'src/app/admin-core/constants';
import {NotificationService} from 'src/app/admin-core/apiservices/notification.service';
import {RequestParamModel} from 'src/app/admin-core/models/request-params.model';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css', '../header/sidebar/notification-sidebar/notification-sidebar.component.css']
})
export class NotificationsComponent implements OnInit {
  requestParamModel = new RequestParamModel();
  isLoading = false;
  userProfileDetail: any;
  userNotifications: any;
  private sidebarSubscription: Subscription;
  private resetSidebarSubscription: Subscription;
  isSidebarLoading = false;
  toggleStyle: boolean = false;
  s3BucketImageURL: any
  createClass: boolean = false;
  detailClass: boolean = false;
  toggleVariable: boolean = false;
  constants = Constants;
  isAllNotificationRead: boolean = true;
  isToggledOn = true;
  totalPageSize = 1;
  unReadNotificationCount = 0;
  hasMoreData: boolean = true;

  constructor(private alertService: AlertService, private router: Router, private changeDetection: ChangeDetectorRef, private subscribersService: SubscribersService, public helperFunction: HelperService, private notificationService: NotificationService) {
    let userProfile = localStorage.getItem('admin_user');
    this.userProfileDetail = JSON.parse(userProfile);

    this.sidebarSubscription = this.subscribersService.notificationSidebar.subscribe((options) => {
      this.toggleStyle = options;
    });

    this.resetSidebarSubscription = this.subscribersService.resetNotificationSidebar.subscribe((options) => {
      if (options) {
      }
    });
    this.subscribersService.emitNotificationSidebarStatus(false);
  }

  /************************************
   * Life cycle hooks start
   ************************************/

  ngOnInit(): void {
    this.s3BucketImageURL = Constants.S3_PRODUCT_RESOURCE_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING';
    this.getNotifications();
  }

  ngOnDestroy(): void {
    this.sidebarSubscription.unsubscribe();
    this.resetSidebarSubscription.unsubscribe();
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Notification fetching and display start
   ************************************/
  getNotifications() {
    this.isLoading = true;
    this.requestParamModel.expands = ['fromUser', 'toUser', 'note.quote', 'company', 'quoteProduct.quote', 'noteReaction.note', 'noteReaction.note.quote'];
    this.requestParamModel.pageSize = 20;
    this.requestParamModel.removeDefaultFilter('to_user_id');
    this.requestParamModel.addDefaultFilter('to_user_id', this.userProfileDetail.id, 'number');
    this.requestParamModel.sortChange('id', 'desc');

    if (this.isToggledOn) {
      this.requestParamModel.removeDefaultFilter('status');
      this.requestParamModel.addDefaultFilter('status', Constants.NOTIFICATION_STATUS_INACTIVE, 'number');
    }

    this.requestParamModel.pageIndex = this.totalPageSize;

    this.notificationService.getUserNotifications(this.requestParamModel).subscribe((dataList) => {
      this.isLoading = false;
      // Add new data to existing notifications
      if (this.userNotifications && this.userNotifications.length > 0) {
        this.userNotifications = this.userNotifications.concat(dataList.data);
      } else {
        this.userNotifications = dataList.data;
      }

      // Hide "Show More" button if all data is loaded
      if (dataList.data.length < this.requestParamModel.pageSize) {
        this.hasMoreData = false; // flag to hide button
      }

      this.isDisplayMarkAllAsReadText();

      if (this.isAllNotificationRead) {
        this.subscribersService.updateNotificationBellIcon(true);
      } else {
        this.subscribersService.updateNotificationBellIcon(false);
      }
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
      console.error(error);
    });
  }

  // Function to load more data
  showMoreNotification() {
    if (this.hasMoreData) {  // Check if more data is available
      this.totalPageSize++;
      this.getNotifications();
    }
  }

  /************************************
   * Notification fetching and display end
   ************************************/

  /************************************
   * Notification status management start
   ************************************/

  toggleMarkAsRead(event: any, notification: any) {
    let isChecked = event.target.checked;
     let json = {
         status: isChecked ? Constants.NOTIFICATION_STATUS_ACTIVE : Constants.NOTIFICATION_STATUS_INACTIVE,
         type: notification.note_id ? Constants.NOTE_NOTIFICATION : Constants.QUOTE_PRODUCT_NOTIFICATION
     };

     this.notificationService.markNotificationAsRead(json, notification.id).subscribe((response: any) => {
         isChecked ? this.alertService.success(Constants.NOTIFICATION_MARKED_AS_READ_SUCCESS_MESSAGE) : this.alertService.success(Constants.NOTIFICATION_MARKED_AS_UNREAD_SUCCESS_MESSAGE);

         let notificationToUpdate = this.userNotifications.find(message => message.id === notification.id);
         if (notificationToUpdate) {
             notificationToUpdate.status = response.data.status;
         }
         if (this.isToggledOn) {
             this.userNotifications = this.userNotifications.filter(item => item.id !== notification.id);
         }
         this.subscribersService.updateUnreadNotification(this.unReadNotificationCount.toString());
         this.isDisplayMarkAllAsReadText();
     });
 }

  toggleMarkAllAsRead() {
    this.isAllNotificationRead = false;

    let json = {
      to_user_id: this.userProfileDetail.id
    }

    this.notificationService.markAllNotificationsAsRead(json).subscribe(res => {
      this.alertService.success(Constants.ALL_NOTIFICATION_MARKED_AS_READ_SUCCESS_MESSAGE);
      if (this.isToggledOn) {
        this.userNotifications = [];
      } else {
        this.userNotifications.forEach(notification => notification.status = 1);
      }
    })
  }

  isDisplayMarkAllAsReadText() {
    let isAllNotificationRead = this.userNotifications.every(item => {
      if (item.status == Constants.NOTIFICATION_STATUS_ACTIVE) {
        return true;
      } else {
        return false;
      }
    });
    if (isAllNotificationRead) {
      this.isAllNotificationRead = false
    } else {
      this.isAllNotificationRead = true
    }
  }

  onChangeToggleIcon() {
    this.totalPageSize = 1;
    // Toggle the state of the icon
    this.isToggledOn = !this.isToggledOn;
    // Clear previous notifications
    this.userNotifications = [];
    // Set the flag to show/hide the "Show More" button
    this.hasMoreData = true; // Reset to true to allow fetching new data
    // Fetch the updated notifications based on the new toggle state
    this.getNotifications();
  }
  /************************************
   * Notification status management end
   ************************************/

  /************************************
   * Helper functions start
   ************************************/

  getUserImage(profile_picture: any): string {
    const getKitPath = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL;
    if (profile_picture != '' && profile_picture != null) {
      return getKitPath + '/USER_PROFILE_PICTURE/' + profile_picture + this.constants.IMAGE_SIZE_50_X_50;
    }
  }

  getNotificationSenderName(fromUser: any) {
    if (fromUser) {
      let firstName = fromUser.first_name ? fromUser.first_name : '';
      let lastName = fromUser.last_name ? fromUser.last_name : '';

      let notificationSenderFullName = `${firstName} ${lastName}`;
      return notificationSenderFullName;
    }
    return '';
  }

  setNotificationStatus(status: number): boolean {
    if (status && status == Constants.NOTIFICATION_STATUS_ACTIVE) {
      return true
    }
    return false
  }

 redirectToModule(notification) {
    if(notification.note_id)
    {
      if (!notification || !notification.note) {
        return;
      }
     const note = notification.note;
    const id = note.quote_id;
    const type = note.quote ? note.quote.type : null;
     if (type) {
        let route = '';
        switch (type) {
            case Constants.ORDER_TYPE_SAMPLE_SHORT:
                route = 'sample-requests/view/';
                break;
            case Constants.ORDER_TYPE_QUOTE_SHORT:
                route = 'quotes/view/';
                break;
            case Constants.ORDER_TYPE_ORDER_SHORT:
                route = 'orders/view/';
                break;
            default:
                // Handle unknown type scenario if needed
                break;
        }
      if (route) {
            this.router.navigate([route, id]);
        }
    }
    else {
        if (note.company_id !== null) {
            this.router.navigate(['accounts/', note.company_id]);
        } else {
            this.router.navigate(['contacts/', note.frontend_user_id]);
        }
    }
  }
    if(notification.quote_product_id)
    {
      if (!notification || !notification.message) {
        return;
      }
      const note = notification.quoteProduct;
      const id = note.quote_id;
      const type = note.quote ? note.quote.type : null;
      if (type) {
        let route = '';
        switch (type) {
            case Constants.ORDER_TYPE_SAMPLE_SHORT:
                route = 'sample-requests/view/';
                break;
            case Constants.ORDER_TYPE_QUOTE_SHORT:
                route = 'quotes/view/';
                break;
            case Constants.ORDER_TYPE_ORDER_SHORT:
                route = 'orders/view/';
                break;
            default:
  break;
        }
      if (route) {
            this.router.navigate([route, id]);
      }
     }
      }

    }
/************************************
   * Helper functions end
   ************************************/
}
