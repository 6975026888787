<div class="notifications-main"
     [ngClass]="{'show': toggleStyle, 'hide': !toggleStyle, 'add-item-sidebar': createClass,'detail-page-add-item-sidebar': detailClass}">
    <div class="add-item-container widget-loader-parent" [ngClass]="{ 'active-container' : toggleVariable }">
      <ng-container *ngIf="isLoading">
        <div [class]="isLoading?'widget-page-loader':'widget-page-loader d-none'"></div>
      </ng-container>

      <div class="notifications-header">
        <div class="d-flex align-items-center justify-content-between notifications-header-inner">
          <h3 class="quote-widget-title">Notifications</h3>
          <i class="close-icon">
            <img src="{{s3BucketImageURL}}/close-icon.svg" (error)="helperFunction.getErrorImage($event)"
                 (click)="closeAddItemSidebar($event)"/>
          </i>
        </div>
        <div class="d-flex align-items-center justify-content-between notifications-header-mark-all">
          <div class="d-flex align-items-center left-side">
            <p>Only show unread</p>
            <i class="fa"
               [class.fa-toggle-on]="isToggledOn"
               [class.fa-toggle-off]="!isToggledOn"
               (click)="onChangeToggleIcon()">
            </i>
          </div>
          <a *ngIf="isAllNotificationRead" class="text-golden see-all-notification" (click)="toggleMarkAllAsRead()">Mark
            all as read</a>
        </div>
      </div>
      <div class="notifications-body">
        <ng-container *ngIf="userNotifications && userNotifications.length > 0">
          <div class="d-flex recent-activity-user-details" *ngFor="let notification of userNotifications">
            <img class="profile-image" src="{{getUserImage(notification.fromUser.profile_picture)}}"
            (error)="helperFunction.getErrorImage($event, 'default_profile.png')" width="42" height="42" alt="User Image"/>
            <div class="">
              <div class="d-flex notification-user-detail"(click)="redirectToModule(notification.note_reaction_id ? notification.noteReaction : notification)">
                <div class="notification-user-inner">
                  <!-- <p>{{getNotificationSenderName(notification.fromUser)}} <span><span>mentioned you</span>
                    <span class="notification-date-time">{{notification.created_at | dateAgo}}</span></span></p> -->
                    <p>
                      {{ getNotificationSenderName(notification.fromUser) }}
                      <span>
                        <span *ngIf="notification.quote_product_id; else mentionedInNote">mentioned you in a comment</span>
                        <ng-template #mentionedInNote>
                          <span *ngIf="notification.note_id">mentioned you in a note</span>
                          <span *ngIf="notification.noteReaction?.id">liked your note</span>
                        </ng-template>
                        <span class="notification-date-time">{{ notification.created_at | dateAgo }}</span>
                      </span>
                    </p>
                </div>
              </div>
              <div class="notification-message-container">
                <div class="notification-type" style="align-items: start;">
                  <label class="checkbox-custom">
                    <input type="checkbox" (change)="toggleMarkAsRead($event, notification)"
                     (click)="$event.stopPropagation()"
                           [checked]="setNotificationStatus(notification.status)"/>
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div *ngIf="notification.message" [innerHTML]="notification.noteReaction?.id ? notification.noteReaction?.note?.note : notification.message"
               class="rounded notification-message"
                     (click)="redirectToModule(notification.note_reaction_id ? notification.noteReaction : notification)"
                       [ngClass]="{'read': setNotificationStatus(notification.status)}">
                   </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="userNotifications && userNotifications.length == 0">
         <p class="no-notification-custom-class">
          You have no notification
         </p>
        </ng-container>
      </div>
      <div>

      </div>
      <div class="notifications-footer see-all-notification"
           *ngIf="userNotifications && userNotifications.length >= 8">
        <a class="text-golden" (click)="seeAllNotifications()">See all notifications</a>
      </div>
    </div>
</div>
