import {ColumnListModel} from './list-column.model';
import {PaginationModel} from './pagination.model';
import * as moment from 'moment';

export class RequestParamModel {
  pageIndex = 0;
  pageSize?: any;
  sort?: string;
  asc = true;
  fields!: string[];
  assignedto?: string[];
  expands!: string[];
  totalCount!: number;
  totalPage!: number;
  filter?: string;
  defaultFilter = '';
  setOrArrayFilter = '';
  setInArrayFilter = '';
  timer: any;
  sorts?: string;
  pageCount?: any;
  group?: any;

  constructor() {
    this.pageIndex = 0;
    this.pageSize = 20;
  }

  update<T>(pagedList: PaginationModel<T>) {
    this.pageSize = pagedList.pageSize;
    this.totalCount = pagedList.totalCount;
    this.pageIndex = pagedList.currentPage;
    this.totalPage = pagedList.pageCount;
  }

  setFilters(columns: ColumnListModel[]): any {
    this.filter = '';
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].searchable && columns[i].searchValue) {
        const field = columns[i].searchfield;
        const searcvalue = encodeURIComponent(columns[i].searchValue);
        if (columns[i].datatype === 'date') {
          this.filter += '&filter[' + field + ']' + '=' + moment(columns[i].searchValue).format('YYYY-MM-DD');
        } else if (columns[i].datatype == 'in') {
          if (searcvalue !== undefined) {
            for (let i = 0; i < searcvalue.length; i++) {
              if (searcvalue[i] !== '' && searcvalue[i] !== ',') {
                this.setInArrayFilter += '&filter[' + field + '][in][' + i + ']' + '=' + encodeURIComponent(searcvalue[i]);
              }
            }
          }
        } else if (columns[i].datatype !== 'number') {
          this.filter += '&filter[' + field + '][like]' + '=' + searcvalue;
        } else {
          this.filter += '&filter[' + field + ']' + '=' + searcvalue;
        }
      }
    }
  }

  // setFilters(columns: ColumnListModel[]): any {
  //   this.filter = '';
  //   let taskCategoryId = ''; // Variable to hold the value of task_category_id if found
  //
  //   for (let i = 0; i < columns.length; i++) {
  //     if (columns[i].searchable && columns[i].searchValue) {
  //       const field = columns[i].searchfield;
  //       const searchValue = encodeURIComponent(columns[i].searchValue);
  //
  //       // Check if the current column is 'task_category_id'
  //       if (field === 'task_category_id') {
  //         taskCategoryId = searchValue; // Store the value for 'task_category_id'
  //       }
  //
  //       if (columns[i].datatype === 'date') {
  //         this.filter += '&filter[' + field + ']' + '=' + moment(columns[i].searchValue).format('YYYY-MM-DD');
  //       } else if (columns[i].datatype == 'in') {
  //         if (searchValue !== undefined) {
  //           for (let j = 0; j < searchValue.length; j++) {
  //             if (searchValue[j] !== '' && searchValue[j] !== ',') {
  //               this.setInArrayFilter += '&filter[' + field + '][in][' + j + ']' + '=' + encodeURIComponent(searchValue[j]);
  //             }
  //           }
  //         }
  //       } else if (columns[i].datatype !== 'number') {
  //         // this.filter += '&filter[' + field + ']' + '=' + searchValue;
  //         this.filter += '&filter[' + field + '][like]' + '=' + searchValue;
  //       } else {
  //         this.filter += '&filter[' + field + ']' + '=' + searchValue;
  //       }
  //     }
  //   }
  // }

  addDefaultFilter(field: string, value: any, datatype: string) {
    if (field && (value || value == 0)) {
      if (datatype !== 'number') {
        this.defaultFilter += '&filter[' + field + ']' + '[like]' + '=' + value;
      } else {
        this.defaultFilter += '&filter[' + field + ']' + '=' + value;
      }
    }
  }

  removeDefaultFilter(field: string) {
    var removeFilterIndex: any = [];
    if (field && this.defaultFilter) {
      var defaultFilterArray = this.defaultFilter.split('&');
      if (defaultFilterArray.length > 0) {
        defaultFilterArray.forEach(function (value, index) {
          if (value.includes(field)) {
            // removeFilterIndex.push(index);
            defaultFilterArray.splice(index);
          }
        });

        this.defaultFilter = defaultFilterArray.join("&");
      }
    }
  }

  addDefaultSorting(field: string, sortType: string) {
    this.sorts += (this.sorts != '' ? ',' : '') + (sortType === 'desc' ? '—' : '') + field
  }

  filterInWithArray(arr: any, field: any) {
    this.setInArrayFilter = '';
    if (arr !== undefined) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== '') {
          this.setInArrayFilter += '&filter[' + field + '][in][' + i + ']' + '=' + encodeURIComponent(arr[i]);
        }
      }
    }
  }

  filterOrWithArray(arr: any, field: any) {
    this.setOrArrayFilter = '';
    if (arr !== undefined) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== '') {
          this.setOrArrayFilter += '&filter[' + field + '][or][' + i + ']' + '=' + encodeURIComponent(arr[i]);
        }
      }
    }
  }

  filterNotInWithArray(arr: any, field: any) {
    this.setInArrayFilter = '';
    if (arr !== undefined) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== '') {
          this.setInArrayFilter += '&filter[' + field + '][nin][' + i + ']' + '=' + encodeURIComponent(arr[i]);
        }
      }
    }
  }

  removeNotInFilter(field: string) {
    var removeFilterIndex: any = [];
    if (field && this.setInArrayFilter) {
      var defaultFilterArray = this.setInArrayFilter.split('&');
      if (defaultFilterArray.length > 0) {
        defaultFilterArray.forEach(function (value, index) {
          if (value.includes(field)) {
            // removeFilterIndex.push(index);
            defaultFilterArray.splice(index);
          }
        });

        this.setInArrayFilter = defaultFilterArray.join("&");
      }
    }
  }

  setDefaultFiltersDropdown(field: string, value: any, datatype: string) {
    if (field && value) {
      if (datatype !== 'number') {
        if (datatype === 'date') {
          this.defaultFilter += '&filter[dropdown][' + field + ']' + '=' + moment(value).format('YYYY-MM-DD');
        } else {
          this.defaultFilter += '&filter[dropdown][' + field + ']' + '[like]' + '=' + encodeURIComponent(value);
        }
      } else {
        this.defaultFilter += '&filter[dropdown][' + field + ']' + '=' + encodeURIComponent(value);
      }
    }
  }

  addAssignedtoFilter(field: string, value: any) {
    if (field && value) {
      this.defaultFilter += '&assignedto[' + field + ']' + '=' + encodeURIComponent(value);
    } else if (field) {
      this.defaultFilter += '&assignedto[' + field + ']' + '=' + null;
    }
  }

  resetClear(columns: ColumnListModel[]) {
    this.filter = '';
    for (let i = 0; i < columns.length; i++) {
      columns[i].searchValue = '';
    }

  }

  setSorting(columns: ColumnListModel[]): any {
    this.sorts = '';
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sortOrder && columns[i].sortOrder != '') {
        if (columns[i].property == 'last_login') {
          this.sorts += (this.sorts != '' ? ',' : '') + (columns[i].sortOrder === 'desc' ? '' : '-') + columns[i].property
        } else {
          this.sorts += (this.sorts != '' ? ',' : '') + (columns[i].sortOrder === 'desc' ? '-' : '') + columns[i].property
        }
      }
    }
  }

  resetSorting(columns: ColumnListModel[]) {
    this.sorts = '';
    for (let i = 0; i < columns.length; i++) {
      columns[i].sortOrder = '';
    }

  }

  sortChange(field: string, sortType: string): any {
    if (field) {
      if (field == 'last_login') {
        this.sorts = (sortType === 'desc' ? '' : '-') + field
      } else {
        this.sorts = (sortType === 'desc' ? '-' : '') + field
      }

    }
  }

  getParams(): string {
    // TODO : Optimize to use the URLQueryParams
    let param = '';

    if (this.defaultFilter) {
      param += this.defaultFilter;
    }

    if (this.filter) {
      param += this.filter;
    }
    const fieldParam = this.getListParam('fields', this.fields);
    if (fieldParam) {
      param += '&' + fieldParam;
    }

    const expandParam = this.getListParam('expand', this.expands);
    if (expandParam) {
      param += '&' + expandParam;
    }

    if (this.sorts) {
      param += '&sort=' + (!this.asc ? '—' : '') + this.sorts;
    }

    if (this.pageIndex) {
      param += '&page=' + this.pageIndex;
    }
    if (this.pageSize) {
      param += '&pageSize=' + this.pageSize;
    }
    if (this.group) {
      param += '&group=' + this.group;
    }
    if (param) {
      param = param.replace('&', '?');
    }

    if (this.setInArrayFilter) {
      param += this.setInArrayFilter;
    }

    if (this.setOrArrayFilter) {
      param += this.setOrArrayFilter;
    }

    return param;
  }

  private getListParam(paramName: string, paramValue: string[]): string {
    let param = '';
    if (paramValue) {
      param = paramName + '=' + paramValue.join(',');
    }
    return param;
  }
}
