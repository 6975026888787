export class AddressModel {
    address: any;
    company_id: any;
    id: any;
    status: any;
    latitude: any;
    longitude: any;
    frontend_user_id: any;
    post_code: any;
    fullAddress?:any;
    address_line_1?:any;
    address_line_2?:any;
    suburb?:any;
    town_city?:any;
    state_region?:any;
    country?:any;
    is_default?:any;
    is_warehouse_collection?:any;
    is_sales_rep_delivery?:any
}
